//对api 接口进行统一管理
import requests from './request'


// 关于我们
export const aboutWe = () => requests({
  url: `/app/junior/acquire/dictionary/info?type=6603`,
})


// 二维码
export const qrCode= () => requests({
  url: '/app/junior/acquire/dictionary/info?type=6604',
})

// look
export const look = () => requests({
  url: `/app/junior/acquire/dictionary/info?type=6605`,
})

// search
export const search = (keyWords) => requests({
  url: `/pc/tutor/search/tutor?keyWords=${keyWords}`,
})

export const commentDetail = (params) => requests({
  url: `/pc/tutor/get/tutor/comment`,
  params
})



