// 对 axios 进行二次封装
import axios from "axios";


//1. 利用axios对象的create()
const requests = axios.create({
	baseURL : '',
	timeout : 5000,
});

//请求拦截器： 在发请求之前，请求拦截器可以监测到, 可以在请求发出去之前做一些事情
requests.interceptors.request.use((config) => {
	//config: 配置对象， 对象里面有一个属性非常重要,headers请求头

	return config;
}, err => {
	console.log(err)
});

//响应拦截器
requests.interceptors.response.use(res => {
	//成功的回调函数： 服务器响应数据回来以后，
	return res.data
}, err => {
	return Promise.reject(err)
});


//默认暴露
export default requests;