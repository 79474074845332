<template>
  <div class="search-detail">
    <div class="search">
      <el-autocomplete class="inline-input" prefix-icon="el-icon-search" v-model="keyword"
        :fetch-suggestions="querySearch" placeholder="请输入导师姓名查询" :trigger-on-focus="false" @select="handleSelect">
        <button slot="append" class="search-btn" @click="querySearchBtn">清空</button>
      </el-autocomplete>
    </div>
    <div class="comment" >
      <div>
        <el-button type="success" icon="el-icon-bell">评价来自学生匿名，如有不实信息请联系 2020268250@qq.com 及时删除</el-button>
      </div>
      <div class="info" v-if="userInfo">
        <p>{{ userInfo.name }}</p>
        <el-button v>{{ userInfo.school }}</el-button>
        <el-button>{{ userInfo.department }}</el-button>
      </div>

      <div class="item" v-for="(item, index) in comment" :key="index">
        <div class="header-item">
          <span>匿名</span>
          <span title="评分仅供参考">{{ item.rate }}</span>
        </div>

        <div class="content">
          <div class="val">
            <p v-html="item.description"></p>
            <span class="dim" v-html="item.descriptionHidden"></span>
            <span class="icon" v-if="item.descriptionHidden" @click="showQRCode">查看详情 <i class="el-icon-arrow-down"></i>
            </span>
          </div>
        </div>

      </div>

      <div class="wu" v-if="comment.length === 0 && isShow" >
        <img style="width: 300px;" :src="look" alt="">
        暂无评价，微信扫码查看后续更新
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" custom-class="dialog"  v-if="qrCode">
      <img class="qr-code" :src="qrCode" alt="">
      <span>微信扫码查看评价</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>


    <div class="fl-center">
      <el-link href="https://beian.miit.gov.cn/" target="_blank"><span class="ft-16">浙ICP备2023002559号-6</span></el-link>
    </div>
  </div>
</template>

<script>

import { search, commentDetail, qrCode, look } from '@/network/home'
export default {
  name: 'searchDetail',
  data() {
    return {
      keyword: '',
      keywordList: [],
      comment: [],
      userInfo: this.$route.query.userInfo,
      qrCode: '',
      dialogVisible: false,
      look: '',
      isShow: ''
    }
  },

  created() {
    if (this.$route.query.userInfo) {
      this.listData()
    }

  },
  methods: {

    async listData() {
      const res = await commentDetail(this.$route.query.userInfo)
      // console.log(res);
      this.comment = res.result

      if(this.comment.length === 0) {
        this.isShow =true
        const res2 = await look()
        this.look = res2.result
      }

      
    },

    async requestSearch(keyword) {
      const res = await search(keyword)
      this.keywordList = res.result.tutors.map(item => {
        return {
          value: item.name + `- ${item.department}-` + item.school,
          tutorId: item.tutorId,
          name: item.name,
          department: item.department,
          school: item.school,
        }
      })

    },

    async querySearch(queryString, cb) {
      const result = await this.requestSearch(this.keyword)

      var restaurants = this.keywordList;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    async handleSelect(item) {
      delete item.value

      this.userInfo = item
      const res = await commentDetail(item)
      // console.log(res);
      this.comment = res.result
      if(this.comment.length === 0) {
        this.isShow =true
        const res2 = await look()
        this.look = res2.result
      }

    },

    async showQRCode() {
      this.dialogVisible = true
      const res = await qrCode()
      this.qrCode = res.result
    },

    querySearchBtn() {
      this.keyword = ''
    }
  }
}
</script>

<style scoped lang="less">
.fl-center {
  display: flex;
  justify-content: center;
}
.ft-16{
  font-size: 16px;
}
.search {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  height: 80px;

  .el-autocomplete {
      position: relative;
      width: 41.6667vw;
      min-width: 270px;
      height: 4.1667vw;

      /deep/.el-input-group__append {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 5px;
        right: 5px;
        width: 6.25vw;
        min-width: 60px;
        height: 3.6458vw;
        min-height: 40px;
        border-radius: 35px;
        color: #000;
        font-size: 1.1458vw;
        
        background-color: #8bf2f1;

        button {
          border: 0;
          font-size: 22px;
          background-color: #8bf2f1;

        }
      }

      /deep/.el-input {
        width: 100%;
        height: 100%;
        font-size: 18px;

        .el-input__inner {
          padding-left: 50px;
          width: 100%;
          height: 100%;
          min-height: 50px;
          border-radius: 40px !important;

        }
      }
    }

  /deep/.el-input__icon {
    height: 100%;
    width: 50px;
    text-align: center;
    transition: all .3s;
    line-height: 4.1667vw;
  }

}

.comment {
  margin: 2.6042vw auto;
  width: 80vw;
  max-width: 1000px;

  .info {
    margin-bottom: 20px;

    p {
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      font-size: 24px;
      font-weight: 700;
    }

    span {
      font-size: 24px;
      font-weight: 700;
    }
  }

  .item {
    .header-item {
      display: flex;
      justify-content: space-between;
      height: 50px;
      align-items: center;
      cursor: pointer;
      font-weight: 550;
    }


    .content {

      .val {
        margin-top: 15px;
        white-space: pre-wrap;

        .dim {
          white-space: pre-wrap;
          filter: blur(2px);
          cursor: pointer;
        }

        .icon {
          display: flex;
          justify-content: center;
          cursor: pointer;
        }



      }
    }

  }
  
  .wu {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: red;
    text-align: center;
     img {
      margin-bottom: 10px;
     }
  }

}



/deep/.el-dialog__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 250px;

  span {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 550; 
  }
}

.qr-code {
  width: 200px;
}


@media screen and (max-width: 750px) {
  .el-dialog__wrapper {
    width: 100%;
  }
  /deep/.dialog {
    // margin: 10% auto;
    width: 70%;
  }
}


</style>