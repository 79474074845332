<template>
  <div class="home">
    <!-- 2、banner -->
    <div class="banner">
      <img src="../assets/bannerText.png" alt="">
      <div class="search">
        <el-autocomplete class="inline-input" prefix-icon="el-icon-search" v-model="keyword"
          :fetch-suggestions="querySearch" placeholder="请输入导师姓名查询" :trigger-on-focus="false" @select="handleSelect">
          <button slot="append" class="search-btn" @click="querySearchBtn">清空</button>
        </el-autocomplete>
      </div>
    </div>

    <!-- 3、 about -->
    <div class="mod_help" id="about" v-html="aboutWe"></div>

    <!-- 4、footer -->
    <footer class="footer">
      <div class="copyright">导师评价网- The Realm of Research - 2023</div>
      <div class="route" @click="goRoute">服务条款</div>
    </footer>
    <div class="fl-center pt20">
      <el-link href="https://beian.miit.gov.cn/" target="_blank"><div class="ft-16">浙ICP备2023002559号-6</div></el-link>
    </div>
  </div>
</template>

<script>

import { aboutWe, search,commentDetail } from '@/network/home'

export default {
  name: "home",
  data() {
    return {
      aboutWe: '',
      keyword: '',
      keywordList: [],
      comment: [],
      userInfo: {},
    }
  },
  created() {
    this.initData()
  },
  methods: {
    // 关于我们
    async initData() {
      const res = await aboutWe()
      this.aboutWe = res.result
    },


    async requestSearch(keyword) {
      const res = await search(keyword)
      this.keywordList = res.result.tutors.map(item => {
        return {
          value: item.name + `- ${item.department}-` + item.school,
          tutorId: item.tutorId,
          name: item.name,
          department: item.department,
          school: item.school,
        }
      })

    },

    async querySearch(queryString, cb) {
      const result = await this.requestSearch(this.keyword)
      var restaurants = this.keywordList;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    querySearchBtn() {
      this.keyword = ''
    },

    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    async handleSelect(item) {
      delete item.value

      this.$router.push({
        path: '/searchDetail',
        query: {
          userInfo: item,
        }
      })

    },

    //  服务条款
    goRoute() {
      this.$router.push('/termsOfService')
    },
    goSearch() {
      this.$router.push('/searchDetail')
    }



  }
};
</script>


<style scoped lang="less">
.pt20{
  padding: 20px 0;
}
.ft-16{
  font-size: 16px;
}
.fl-center {
  display: flex;
  justify-content: center;
}
.home {

  /* banner 区域 */
  .banner {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 563px;
    background: url(../assets/banner.png) no-repeat top center;

    img {
      display: flex;
      width: 31.25vw;
    }

    .el-autocomplete {
      position: relative;
      width: 41.6667vw;
      min-width: 270px;
      height: 4.1667vw;

      /deep/.el-input-group__append {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 5px;
        right: 5px;
        width: 6.25vw;
        min-width: 60px;
        height: 3.6458vw;
        min-height: 40px;
        border-radius: 35px;
        color: #000;
        font-size: 1.1458vw;
        
        background-color: #8bf2f1;

        button {
          border: 0;
          font-size: 22px;
          background-color: #8bf2f1;

        }
      }

      /deep/.el-input {
        width: 100%;
        height: 100%;
        font-size: 18px;

        .el-input__inner {
          padding-left: 50px;
          width: 100%;
          height: 100%;
          min-height: 50px;
          border-radius: 40px !important;

        }
      }
    }



  }

  // footer
  .about {
    padding: 30px 100px 0;
    // height: 415px;
    background-color: #f5f5f5;
  }


  /deep/ .home-content {
    padding: 0 5.2083vw;
    color: #0000008a;

    .home-row {
      display: flex;
      gap: 5.2083vw;

      .home-row-column {
        flex: 1;

        h1 {
          margin: 20px 0;
        }

        p {
          margin-bottom: 20px;
        }

        /* li {
          list-style: circle;
        } */
      }

    }

  }



  .footer {
    padding: 0 5.2083vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    color: #fff;
    background-color: #8bf2f1;
    font-size: 20px;

    .route {
      cursor: pointer;
    }
  }

  .comment {

    .info {
      margin-bottom: 20px;

      p {
        display: flex;
        justify-content: space-between;
        font-size: 24px;
        font-weight: 700;
      }

      span {
        font-size: 24px;
        font-weight: 700;
      }
    }



    .item {
      .header-item {
        display: flex;
        justify-content: space-between;
        height: 50px;
        align-items: center;
        cursor: pointer;
        font-weight: 550;
      }


      .content {

        .val {
          margin-top: 15px;
          white-space: pre-wrap;

          .dim {
            white-space: pre-wrap;
            filter: blur(2px);
            cursor: pointer;
          }

          .icon {
            display: flex;
            justify-content: center;
          }

          .qr-code {
            width: 200px;
          }

        }
      }

    }




  }


  /deep/.el-input__icon {
    height: 100%;
    width: 50px;
    text-align: center;
    transition: all .3s;
    line-height: 4.1667vw;
  }


}
</style>
  