<template>
  <div id="app">
    <!-- 1、header -->
    <div class="header">
      <div class="logo" @click="goHome">
        <img src="~@/assets/logo.png" alt="">
      </div>
      <div class="nav">
        <ul>
          <li @click="goHome"><a>首页</a></li>
          <li @click="goSearch"><a>导师评价</a></li>
          <li @click="goAbout"><a href="#about">关于我们</a></li>
        </ul>
      </div>
    </div>

    <KeepAlive include="home">
      <router-view />
    </KeepAlive>
  </div>
</template>

<script>

export default {
  name: 'App',
  methods: {

    goHome() {
      this.$router.push('/home')
    },

    goSearch() {
      this.$router.push('/searchDetail')
    },
    goAbout() {
      this.$router.push('/home#about')
    }
  }
}

</script>



<style lang="less">

@font-face {
  font-family: 'meFont';
  src: url("./assets/font/HarmonyOS_Sans_SC_Black.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.w {
  width: 1200px;
  margin: auto;
}

body {
  background-color: #f3f5f7;

}

li {
  list-style: none;
}

a {
  text-decoration: none;
}


// header
.header {
  padding: 0 5.2083vw;
  display: flex;
  align-items: center;
  height: 80px;
  background-color: #8bf2f1;
  font-family: 'meFont';


  .logo {
    width: 10.3125vw;
    img {
      width: 100%;
      min-width: 50px;
    }
  }

  .nav {
    display: flex;
    align-items: center;
    margin-left: 3.125vw;

    ul {
      display: flex;
      min-width: 200px;
    
      li {
        margin: 0 .7813vw;
        cursor: pointer;

        a {
          display: block;
          height: 80px;
          padding: 0 10px;
          line-height: 80px;
          font-size: 18px;
          color: #050505;
          font-size: 20px;
        }

        a:hover {
          border-bottom: 2px solid #00a4ff;
          color: #00a4ff;
        }
      }
    }


  }


}



</style>
